<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-card no-body v-if="checkPermission($route.name, 'Form Setting')">
        <b-card-body>
            <b-row>
                <b-col cols="12" md="3" class="mb-md-0">
                
                    <!-- <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sites"
                        label="site_name"
                        class="w-100"
                        placeholder="Project Site"
                        v-model="siteData"
                        @input="getForms()"
                    >

                      <template #selected-option="{ site_name }">
                        <span v-if="site_name.length < 23">{{site_name}}</span>
                        <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                      </template>
                    </v-select> -->
                </b-col>
                <b-col md="9">

                    <div class="demo-inline-spacing float-right margin_bottom_zero_mobile">
                  
                      <b-button
                        variant="danger"
                        class="mt-0 mb-1 margin_bottom_zero_mobile common_margin_bottom_zero common_margin_right_zero"
                        @click="openSetting()"
                      >
                      <feather-icon
                          icon="TargetIcon"
                          class="mr-25"
                        />
                        <span>Form Settings</span>
                      </b-badge>
                    </b-button>
                  </div>
              </b-col>

            </b-row>
        </b-card-body>
    </b-card>

    
      
  <b-row class="match-height" v-if="formsList.length > 0">
    
    <b-col
      md="4"
      v-for="(form,index) in formsList"
      @click="redirectRoute(form.adminRoute)"
      :key="index"
      v-if="$store.getters.currentUser.role == 'administrator' && form.title != 'Lactation Checklist'"
    >
      <statistic-card-vertical
        :icon="form.icon"
        :statistic="form.title"
        :color="form.color"
        
      />
    </b-col>

    <b-col
      md="4"
      v-for="(form,index) in formsList"
      @click="redirectRoute(form.form.adminRoute)"
      :key="index"
      v-if="$store.getters.currentUser.role != 'administrator' && form.form.title != 'Lactation Checklist'"
    >
      <statistic-card-vertical
        :icon="form.form.icon"
        :statistic="form.form.title"
        :color="form.form.color"
        
      />
    </b-col>
    
  </b-row>
  
  
  <b-card v-if="formsList.length == 0">
    <b-row class="match-height">

      <b-col md="12" class="text-center">
        {{'No Forms Found'}}
      </b-col>
    
    </b-row>
  </b-card>

      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BCardBody, BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    StatisticCardVertical,
    BBreadcrumb
  },
  data() {
    return {
        webUrl: process.env.VUE_APP_SERVER_URL,
        showDismissibleAlert:false,
        formsList:[],
        siteData:null,
        sites:[],
    }
  },
  methods : {

    getForms(){
      return this.$store.dispatch(POST_API, {
         data:{
           id: this.$store.getters.currentUser.organization,
           role: this.$store.getters.currentUser.role,
         },
         api: '/api/get-custom-forms-by-role'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.formsList = this.$store.getters.getResults.data.custom_forms;
              
              if (this.$store.getters.currentUser.role != 'administrator') {
                  this.formsList.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
              }
              return this.formsList;
          }
      });
    },
    redirectRoute(route_name){
      
      this.$router.push({ name: route_name,params:{site_id:null}})
    },

    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                if (this.sites.length == 1 ) {
                    this.siteData = this.sites[0];
                    this.getForms();
                }
                
                return this.sites;
            }
        });
      },

      /*getForms(){

        this.formsList = [];

        if (this.siteData != null) {
          this.formsList = this.siteData.site_custom_forms;
        }

      },*/
      openSetting(){
        this.$router.push({ name: 'custom-form-settings',params:{site_id:this.siteData ? this.siteData._id : null}});
      },
      breadCrumb(){
        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Custom Forms',
          active:true
        }];
        return item;
      }
    
   
  },
  mounted(){
    this.getForms();
    //this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
